<!--
 * @Descripttion: 
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div>
    <infinite-right-menu
      v-click-outside="showFalse"
      :list="list"
      :startX="this.position.x + 'px'"
      :startY="this.position.y + 'px'"
      :show="this.show"
      @refreshbizlines="clickEvent"
    />
    <delLayer :layer="delLayer" v-if="delLayer.show" :rows="[row]"/>
  </div>
</template>

<script>
  import InfiniteRightMenu from '@/components/right-menu'
  export default {
    components: {
      InfiniteRightMenu,
      delLayer: () => import('./layer/del')
    },
    model: {
      prop: 'show',
      event: 'show'
    },
    props: {
      position: {
        type: Object,
        default: () => {
          return {
            x: 0,
            y: 0
          }
        }
      },
      show: {
        type: Boolean,
        default: false
      },
      row: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    computed: {
      list() {
        if (this.row && this.row.type === 2) {
          return this.lists.filter( item => {
            return item.clickIndex !== 3
          })
        } else {
          return this.lists
        }
      }
    },
    data() {
      return {
        // 菜单名称
        lists: [
          {
            clickIndex: 3,
            headIcon: 'el-icon-download',
            label: '下载'
          },
          {
            clickIndex: 1,
            headIcon: 'el-icon-edit-outline',
            label: '重命名'
          },
          {
            clickIndex: 2,
            headIcon: 'el-icon-delete',
            label: '删除'
          },
        ],
        delLayer: {
          show: false,
          title: '确认删除',
          showButtons: true
        },
       
      }
    
    },
    watch: {

    },
    created() {

    },
    mounted() {
      
    },
    methods: {
      // 全局方法
      clickEvent(key) {
        if (key === 1) {
          this.rename()
        } else if (key === 2) {
          this.delRow()
        } else if (key === 3) {
          this.download()
        }
        // 调用关闭菜单事件
        this.showFalse()
      },
      // 重命名事件
      rename() {
        this.row.showRename = true
      },
      // 删除事件
      delRow() {
        this.delLayer.row = this.row
        this.delLayer.show = true
      },
      // 关闭菜单事件
      showFalse() {
        this.$emit('show', false)
      },
      // 下载功能
      download() {
        window.open(this.row.fileDownloadUrl)
      }
    }
  }
</script>

<style scoped="" lang="scss">

</style>
